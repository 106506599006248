export * from '@solublestudio/soluto-design-system';

export { default as Link, navigate } from 'Link';
export { default as Button } from './src/components/ButtonComponent';

// v2
export { default as FAQsSection } from './src/components/FAQsSection';
export { default as BlogSection } from './src/components/BlogSection';
export { default as HeroPost } from './src/components/HeroPost';
export { default as ValuesSection } from './src/components/ValuesSection';
export { default as OurPartnersSection } from './src/components/OurPartnersSection';
export { default as HeroSectionAlt } from './src/components/HeroSectionAlt';
export { default as OpenPositionSection } from './src/components/OpenPositionSection';
export { default as HTMLSection } from './src/components/HTMLSection';
export { default as ImageTextSection } from './src/components/ImageTextSection';
export { default as BenefitsSection } from './src/components/BenefitsSection';
export { default as IndustriesSection } from './src/components/IndustriesSection';
export { default as SummaryUseCasesSection } from './src/components/SummaryUseCasesSection';
export { default as TestimonialSection } from './src/components/TestimonialSection';
export { default as CardsSection } from './src/components/CardsSection';
export { default as OurSolutionsSection } from './src/components/OurSolutionsSection';
export { default as SummaryPostsSection } from './src/components/SummaryPostsSection';
export { default as DiscoverSection } from './src/components/DiscoverSection';
export { default as HeroSection } from 'HeroSection';
export { default as CtaBanner } from 'CtaBanner';
export { default as Footer } from 'Footer';
export { default as Header } from 'Header';
export { default as FormSection } from 'FormSection';
export { default as NotFoundPage } from 'NotFoundPage';
export { default as IndustriesDetailSection } from './src/components/IndustriesDetailSection';
export { default as PointsSection } from './src/components/PointsSection';
